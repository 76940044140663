const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__content">
        <small className="footer__text">
          © 2025 GetBack Inc. All rights reserved.
        </small>
      </div>
    </footer>
  );
};

export default Footer;
